import React from 'react';

export const Dialog = ({ open, onOpenChange, children }) => {
  if (!open) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded">{children}</div>
      <button onClick={() => onOpenChange(false)}>Close</button>
    </div>
  );
};

export const DialogContent = ({ children }) => <div>{children}</div>;

export const DialogFooter = ({ children }) => (
  <div className="flex justify-end mt-4">{children}</div>
);
