import React, { useState, useEffect, useRef } from 'react';
import { ArrowLeft, ChevronLeft, ChevronRight, ChevronUp, ChevronDown } from 'lucide-react';
import { Button } from './components/ui/button';
import { Dialog, DialogContent, DialogFooter } from './components/ui/dialog';
import { Helmet } from 'react-helmet';


function ArrowButton({ onClick, disabled, children, className }) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`
        w-10 h-10 flex items-center justify-center
        bg-white rounded-full
        ${disabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100 active:scale-95'}
        transition-all duration-100
        ${className}
      `}
    >
      {children}
    </button>
  );
}

export default function WordSliderGame() {
  const gridRef = useRef(null);
  const [score, setScore] = useState(0);
  const [moves, setMoves] = useState(6);
  const [selectedCells, setSelectedCells] = useState([]);
  const [currentSelectedCells, setCurrentSelectedCells] = useState([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const [showSubmitDialog, setShowSubmitDialog] = useState(false);
  const [showWinDialog, setShowWinDialog] = useState(false);
  const [currentWord, setCurrentWord] = useState('');
  const [selectionPoints, setSelectionPoints] = useState([]);
  const [gameData, setGameData] = useState(null);
  const [submittedWords, setSubmittedWords] = useState(new Set()); 
  const [foundWordsCount, setFoundWordsCount] = useState(0); 
  const [topScoringChars, setTopScoringChars] = useState([]);
  const [showInstructions, setShowInstructions] = useState(false);



  // Initialize grid and validWords as empty states
  const [grid, setGrid] = useState([]);
  const [validWords, setValidWords] = useState(new Set());
  const [loading, setLoading] = useState(true); // Loading state for data fetch

 useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch('/word_games.json'); // Ensure JSON file is in the public folder
      const data = await response.json();

      const urlParams = new URLSearchParams(window.location.search);
      const gameId = urlParams.get('gameId');
      setGameData(data);

      let selectedGame;

      if (gameId && !isNaN(gameId) && data.games[parseInt(gameId, 10)]) {
        // Load the game matching the gameId
        selectedGame = data.games[parseInt(gameId, 10)];
      } else {
        // Select a random game if no valid gameId is present
        selectedGame = data.games[Math.floor(Math.random() * data.games.length)];
        const newGameId = data.games.indexOf(selectedGame); // Use index as ID
        window.history.pushState(null, '', `?gameId=${newGameId}`);
      }

      // Set the selected game's data
      setGrid(selectedGame.grid);
      setValidWords(new Set(selectedGame.validWords));
      setTopScoringChars(
        [...selectedGame.grid.flat()]
          .sort((a, b) => b.value - a.value)
          .slice(0, 3)
          .map((char) => char.letter)
      );
      setLoading(false);
    } catch (error) {
      console.error('Error loading game data:', error);
    }
  };

  fetchData();
}, []);


  const resetGame = () => {
  setScore(0);
  setMoves(6);
  setSelectedCells([]);
  setCurrentSelectedCells([]);
  setIsSelecting(false);
  setShowSubmitDialog(false);
  setShowWinDialog(false);
  setCurrentWord('');
  setSelectionPoints([]);
  setSubmittedWords(new Set());
  setFoundWordsCount(0); // Reset progress
};


  const getCellCenter = (element) => {
    if (!element || !gridRef.current) return null;
    const rect = element.getBoundingClientRect();
    const gridRect = gridRef.current.getBoundingClientRect();
    return {
      x: rect.left + rect.width / 2 - gridRect.left,
      y: rect.top + rect.height / 2 - gridRect.top
    };
  };

  const isAdjacent = (cell1, cell2) => {
    const dx = Math.abs(cell1.row - cell2.row);
    const dy = Math.abs(cell1.col - cell2.col);
    return dx <= 1 && dy <= 1;
  };

  const canAddCell = (newCell) => {
    if (selectedCells.length === 0) return true;
    
    const lastCell = selectedCells[selectedCells.length - 1];
    if (!isAdjacent(newCell, lastCell)) return false;

    return !selectedCells.some(cell => cell.row === newCell.row && cell.col === newCell.col);
  };

  const startSelection = (rowIndex, colIndex, element) => {
  setIsSelecting(true);
  const newCell = { row: rowIndex, col: colIndex };
  setSelectedCells([newCell]);
  setCurrentSelectedCells([newCell]);
  const center = getCellCenter(element);
  if (center) {
    setSelectionPoints([center]);
  }
};

const continueSelection = (rowIndex, colIndex, element) => {
  if (!isSelecting) return; // Allow path creation regardless of moves
  const newCell = { row: rowIndex, col: colIndex };
  if (canAddCell(newCell)) {
    const center = getCellCenter(element);
    if (center) {
      setSelectedCells(prev => [...prev, newCell]);
      setCurrentSelectedCells(prev => [...prev, newCell]);
      setSelectionPoints(prev => [...prev, center]);
    }
  }
};

const endSelection = () => {
  if (!isSelecting || !selectedCells.length) return;

  const word = selectedCells
    .map(cell => grid[cell.row][cell.col].letter)
    .join('');

  if (validWords.has(word) && !submittedWords.has(word)) {
    setCurrentWord(word);
    setCurrentSelectedCells([...selectedCells]);
    setShowSubmitDialog(true);
  } else {
    setSelectedCells([]);
    setCurrentSelectedCells([]);
    setSelectionPoints([]);
  }

  setIsSelecting(false);
};

const newGame = () => {
  window.location.href = '/'; // Redirect to the home page
};


const getSelectionPath = () => {
  if (selectionPoints.length < 2) return ''; // No path if less than 2 points

  let path = `M ${selectionPoints[0].x} ${selectionPoints[0].y}`; // Start at the first point

  for (let i = 1; i < selectionPoints.length; i++) {
    path += ` L ${selectionPoints[i].x} ${selectionPoints[i].y}`; // Draw a line to each point
  }

  return path;
};

  const calculateWordScore = () => {
    return currentSelectedCells.reduce((sum, cell) => {
      return sum + grid[cell.row][cell.col].value;
    }, 0);
  };

  const handleCancel = () => {
    setShowSubmitDialog(false); // Hide the submit box
    setSelectedCells([]); // Clear selected cells
    setSelectionPoints([]); // Clear the path points
  };

  const handleSubmit = () => {
    if (!validWords.has(currentWord) || submittedWords.has(currentWord)) {
      setShowSubmitDialog(false); // Invalid word or already submitted
      return;
    }

    const newSubmittedWords = new Set([...submittedWords, currentWord]);
    setSubmittedWords(newSubmittedWords);

    const newCount = newSubmittedWords.size;
    setFoundWordsCount(newCount); // Update progress

    const wordScore = selectedCells.reduce(
      (sum, cell) => sum + grid[cell.row][cell.col].value,
      0
    );
    setScore((prevScore) => prevScore + wordScore);

    setSelectionPoints([]);
    setSelectedCells([]);
    setShowSubmitDialog(false);

    if (newCount === 6) {
      setTimeout(() => setShowWinDialog(true), 500);
    }
  };


   if (loading) {
    return <div className="w-full max-w-md mx-auto p-4 bg-white rounded-xl shadow-lg">Loading...</div>;
  }

  const moveRow = (rowIndex, direction) => {
  if (!moves) return; // Prevent moving rows if no moves are left
  const newGrid = [...grid];
  const row = [...newGrid[rowIndex]];

  if (direction === 'left') {
    const first = row.shift();
    row.push(first);
  } else if (direction === 'right') {
    const last = row.pop();
    row.unshift(last);
  }

  newGrid[rowIndex] = row;
  setGrid(newGrid);
  setMoves((prevMoves) => prevMoves - 1); // Decrement moves
};

const moveColumn = (colIndex, direction) => {
  if (!moves) return; // Prevent moving columns if no moves are left
  const newGrid = grid.map(row => [...row]);
  const column = newGrid.map(row => row[colIndex]);

  if (direction === 'up') {
    const first = column.shift();
    column.push(first);
  } else if (direction === 'down') {
    const last = column.pop();
    column.unshift(last);
  }

  for (let i = 0; i < newGrid.length; i++) {
    newGrid[i][colIndex] = column[i];
  }

  setGrid(newGrid);
  setMoves((prevMoves) => prevMoves - 1); // Decrement moves
};

const handleTouchStart = (rowIndex, colIndex, element) => {
  startSelection(rowIndex, colIndex, element);
};

const handleTouchMove = (e) => {
  const touch = e.touches[0];
  const element = document.elementFromPoint(touch.clientX, touch.clientY);
  if (element?.hasAttribute('data-coords')) {
    const [row, col] = element.getAttribute('data-coords').split(',');
    continueSelection(parseInt(row), parseInt(col), element);
  }
};

const handleTouchEnd = () => {
  endSelection();
};



  return (
      
    <div className="w-full max-w-md mx-auto p-4 bg-white rounded-xl shadow-lg">

         <Helmet>
               
                <title>TumbleWords - TED Netflix Game Free</title>
                

                <meta name="description" content="TumbleWords is an exciting and unlimited version of the popular TED Netflix game, offering endless fun and brain-teasing word challenges." />

              
                <link rel="canonical" href="https://www.tumblewords.org/" />
            </Helmet>


      <div className="relative w-full bg-gray-200 h-3 rounded-full mb-2">
        <div
          className="absolute top-0 left-0 h-3 bg-blue-500 rounded-full transition-all duration-200"
          style={{ width: `${(foundWordsCount / 6) * 100}%` }}
        ></div>
      </div>

      {/* Found Words Count */}
      <div className="flex items-center justify-between mb-4">
        <button
        onClick={() => setShowInstructions(true)}
        className="text-blue-600 underline font-medium hover:text-blue-800"
      >
        How to Play
      </button>
        <div className="text-right text-lg font-bold">
          {foundWordsCount}/6
        </div>
      </div>

          <div className="text-center mb-4">
            <div className="text-6xl font-bold">{score}</div>
          </div>

          <div className="bg-red-500 p-2 mb-4 rounded">
            <div className="text-white flex items-center justify-center">
              Moves {Array(6).fill(0).map((_, i) => (
                <span key={i} className={`inline-block w-3 h-3 mx-1 rounded-full ${i < moves ? 'bg-white' : 'bg-white/50'}`} />
              ))}
            </div>
          </div>

      <div className="relative p-12" ref={gridRef}>
       <svg 
            className="absolute inset-0 pointer-events-none"
            width={gridRef.current?.offsetWidth || '100%'}
            height={gridRef.current?.offsetHeight || '100%'}
            style={{ zIndex: 60 }}
          >
            {/* Draw the rounded path connecting points */}
            <path
              d={getSelectionPath()} /* Function to compute the path */
              className={showSubmitDialog ? "hidden" : ""}
              stroke="#3b82f6" /* Stroke for the path */
              strokeWidth="40" /* Thickness of the path for the "tube" effect */
              fill="none" /* No fill for the path */
              strokeLinecap="round" /* Rounded ends of the path */
              strokeLinejoin="round" /* Rounded corners */
            />

            {/* Draw circles at each point */}
            {selectionPoints.map((point, index) => (
              <circle
                key={index}
                cx={point.x} /* X-coordinate of the center */
                cy={point.y} /* Y-coordinate of the center */
                r="20" /* Radius of the circle */
                stroke="black" /* Stroke color of the circle */
                strokeWidth="0" /* Stroke thickness of the circle */
                fill="transparent" /* Fill color of the circle (transparent white) */
              />
            ))}
        </svg>

        <div className="grid grid-cols-3 gap-3 relative">
  {grid.map((row, rowIndex) =>
    row.map((cell, colIndex) => {
      const isSelected = selectedCells.some(
        (sel) => sel.row === rowIndex && sel.col === colIndex
      );
      const isHighlighted = topScoringChars.includes(cell.letter);

      return (
        <div
          key={`${rowIndex}-${colIndex}`}
          className={`
            grid-cell
            w-full aspect-square flex items-center justify-center text-3xl font-bold 
            border-2 rounded-xl
            ${isHighlighted ? 'bg-yellow-300' : 'bg-white'}
            ${isSelected ? 'bg-blue-500 text-white scale-110' : ''}
            relative
            cursor-pointer
            transition-all duration-100
            hover:bg-blue-50
            shadow-md
          `}
          onMouseDown={(e) => startSelection(rowIndex, colIndex, e.currentTarget)}
          onMouseEnter={(e) => continueSelection(rowIndex, colIndex, e.currentTarget)}
          onMouseUp={endSelection}
          onTouchStart={(e) => handleTouchStart(rowIndex, colIndex, e.currentTarget)}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          data-coords={`${rowIndex},${colIndex}`}
        >
          {cell.letter}
          <span className="text-xs absolute bottom-1 right-2">{cell.value}</span>
        </div>
      );
    })
  )}
</div>

        <div className="absolute -top-0 left-8 right-8 grid grid-cols-3 gap-3">
          {[0, 1, 2].map(colIndex => (
            <div key={`top-${colIndex}`} className="flex justify-center">
              <ArrowButton onClick={() => moveColumn(colIndex, 'up')} disabled={!moves}>
                <ChevronUp className="w-6 h-6" />
              </ArrowButton>
            </div>
          ))}
        </div>

        <div className="absolute -bottom-0 left-8 right-8 grid grid-cols-3 gap-3">
          {[0, 1, 2].map(colIndex => (
            <div key={`bottom-${colIndex}`} className="flex justify-center">
              <ArrowButton onClick={() => moveColumn(colIndex, 'down')} disabled={!moves}>
                <ChevronDown className="w-6 h-6" />
              </ArrowButton>
            </div>
          ))}
        </div>

        <div className="absolute -left-1 top-8 bottom-8 flex flex-col justify-around">
          {[0, 1, 2].map(rowIndex => (
            <ArrowButton
              key={`left-${rowIndex}`}
              onClick={() => moveRow(rowIndex, 'left')}
              disabled={!moves}
            >
              <ChevronLeft className="w-6 h-6" />
            </ArrowButton>
          ))}
        </div>

        <div className="absolute -right-1 top-8 bottom-8 flex flex-col justify-around">
          {[0, 1, 2].map(rowIndex => (
            <ArrowButton
              key={`right-${rowIndex}`}
              onClick={() => moveRow(rowIndex, 'right')}
              disabled={!moves}
            >
              <ChevronRight className="w-6 h-6" />
            </ArrowButton>
          ))}
        </div>
      </div>

       <div className="flex justify-evenly mt-6">
          <button
    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
    onClick={newGame}
  >
    New Game
  </button>
        

         <Button
  className="bg-yellow-500 text-white hover:bg-yellow-600 px-4 py-2 rounded shadow"
  onClick={() => {
    const urlParams = new URLSearchParams(window.location.search);
    const gameId = urlParams.get('gameId');
    if (gameId) {
      window.location.reload(); // Refresh with the same gameId
    }
  }}
>
  Reset Game
</Button>
  </div>


      <Dialog open={showSubmitDialog} onOpenChange={setShowSubmitDialog}>
        <DialogContent>
          <div className="text-center mb-4">
            <div className="text-2xl font-bold">{currentWord}</div>
            <div className="text-sm">Submit this word?</div>
            <div className="text-lg text-blue-600 mt-2">+{calculateWordScore()} points</div>
          </div>
          <DialogFooter>
            <div className="flex justify-evenly w-full">
                  <button onClick={handleCancel} className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300" style={{marginRight:'10px'}}>
                Cancel
              </button>
              <Button className="bg-red-500 text-white" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={showWinDialog} onOpenChange={setShowWinDialog}>
        <DialogContent>
          <div className="text-center mb-4">
            <div className="text-3xl font-bold mb-2">🎉 Congratulations! 🎉</div>
            <div className="text-xl mb-4">You've found exactly 6 valid words!</div>
            <div className="text-2xl text-blue-600">Final Score: {score}</div>
            <div className="mt-4 text-sm text-gray-600">
              Words found: {Array.from(submittedWords).join(', ')}
            </div>
          </div>
         <DialogFooter className="flex justify-between items-center">
              <Button 
                className="flex-1 bg-green-500 text-white hover:bg-green-600 mx-2"  style={{marginRight:'5px'}} 
                onClick={resetGame}
              >
                Play Again
              </Button>
              <Button
                className="flex-1 bg-blue-500 text-white hover:bg-blue-600 mx-2 flex items-center justify-center gap-2"
                onClick={() => {
                  const gameURL = window.location.href;
                  const shareText = `🎉 I just scored ${score} points in TumbleWords! 🧩 Can you beat me? Play here: ${gameURL}`;
                  navigator.clipboard.writeText(shareText).then(() => {
                    alert('Results copied to clipboard! Share it with your friends!');
                  }).catch((err) => {
                    console.error('Failed to copy:', err);
                    alert('Failed to copy the results. Please try again.');
                  });
                }}
              >
                📋 Copy Results
              </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {showInstructions && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
      <h2 className="text-xl font-bold mb-4 text-blue-600">How to Play</h2>
      <ul className="list-disc pl-6 text-gray-700 space-y-2">
        <li>Each game starts with a random grid of letters and numbers.</li>
        <li>The goal is to find 6 valid English words by connecting letters in the grid.</li>
        <li>You can connect letters horizontally, vertically, or diagonally.</li>
        <li>Move rows or columns using the arrows to rearrange the grid for better words.</li>
        <li>Submit a word by selecting letters and clicking "Submit" to score points.</li>
        <li>You have 6 moves to rearrange the grid, but unlimited attempts to find words.</li>
        <li>Highlighting letters creates a path to visualize your word.</li>
        <li>Reset the game to play the current grid again or start a New Game for a fresh challenge!</li>
      </ul>
      <div className="mt-6 text-right">
        <button
          onClick={() => setShowInstructions(false)}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
}